import { http } from '@/http/axios.js'

// 管理员列表
export function getAdminList(params) {
  return http({
    url: `/company/basic/mangeUser/list`,
    method: 'get',
    params
  })
}
// 新增管理员
export function addAdmin(data) {
  return http({
    url: `/company/basic/mangeUser/add`,
    method: 'post',
    data
  })
}
// 编辑管理员
export function editAdmin(data) {
  return http({
    url: `/company/basic/mangeUser/edit`,
    method: 'post',
    data
  })
}
// 删除管理员
export function removeAdmin(id) {
  return http({
    url: `/company/basic/mangeUser/delete`,
    method: 'post',
    data: { id }
  })
}
// 管理员详情
export function getAdminDetail(id) {
  return http({
    url: `/company/basic/mangeUser/detail`,
    method: 'get',
    params: { id }
  })
}

// 状态
export function changeStatus(data) {
  return http({
    url: '/company/basic/mangeUser/changeStatus',
    method: 'post',
    data
  })
}
