<template>
  <el-select
    class="remote-select"
    :style="{ width: width }"
    size="small"
    v-model="id"
    :loading="isLoading"
    filterable
    remote
    :remote-method="getList"
    placeholder="请选择或输入关键字"
    :multiple="multiple"
    clearable
  >
    <el-option v-for="item in list" :label="item.name" :value="item.id" :key="item.id"></el-option>
  </el-select>
</template>

<script>
import { getDistrictListAPI } from './api.js'
export default {
  name: 'SelectDistrict',

  props: {
    value: {
      type: [String, Number, Array],
      default: ''
    },
    multiple: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '400px'
    }
  },

  computed: {
    id: {
      get() {
        return this.value
      },

      set(val) {
        this.$emit('input', val)
      }
    }
  },

  data() {
    return {
      list: [],
      isLoading: false
    }
  },

  created() {
    this.getList('')
  },

  methods: {
    async getList(query) {
      let params = {
        page: 1,
        page_size: 20,
        name: query
      }
      let res = await getDistrictListAPI(params)
      this.list = res.data
    }
  }
}
</script>

<style lang="scss" scoped>
.el-select /deep/{
  .el-select__caret:first-child::before {
    content: '\e6e1';
  }
  .is-focus {
    .el-select__caret:first-child {
      transform: rotateZ(0deg);
    }
  }
}
</style>
